import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Switch,
    Select,
    Box,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const GenericModalForm = ({ isOpen, onClose, title, formFields, onSubmit, initialValues }) => {

    const validationSchema = Yup.object(
        formFields.reduce((schema, field) => {
            schema[field.name] = field.validation;
            return schema;
        }, {})
    );

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent
                className='dark:!bg-navy-800 dark:!text-white !rounded-xl'
            >
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <Formik
                    initialValues={initialValues || formFields.reduce((values, field) => {
                        values[field.name] = field.defaultValue || (field.type === 'checkbox' ? false : '');
                        return values;
                    }, {})}
                    validationSchema={validationSchema}
                    onSubmit={(values, actions) => {
                        onSubmit(values);
                        actions.setSubmitting(false);
                        // onClose();
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <ModalBody>
                                {formFields.map((field, index) => (
                                    <Field name={field.name} key={index}>
                                        {({ field: formikField, form }) => (
                                            <FormControl
                                                isInvalid={form.errors[formikField.name] && form.touched[formikField.name]}
                                                isRequired={field.isRequired}
                                                mb={4}
                                            >
                                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                                    <FormLabel mb={0} htmlFor={field.name} flex="1">
                                                        {field.label}
                                                    </FormLabel>
                                                    {field.type === 'checkbox' ? (
                                                        <Switch
                                                            {...formikField}
                                                            isChecked={formikField.value}
                                                            onChange={formikField.onChange}
                                                            id={field.name}
                                                            flex="1"
                                                        />
                                                    ) : field.type === 'select' ? (
                                                        <Select
                                                            {...formikField}
                                                            placeholder={field.placeholder || 'Select an option'}
                                                            id={field.name}
                                                            className='!min-w-52'
                                                            flex="1"
                                                        >
                                                            {field.options && field.options.map((option, idx) => (
                                                                <option key={idx} value={option.value}>
                                                                    {option.label}
                                                                </option>
                                                            ))}
                                                        </Select>
                                                    ) : (
                                                        <Input
                                                            {...formikField}
                                                            type={field.type || 'text'}
                                                            placeholder={field.placeholder || ''}
                                                            flex="1"
                                                            onChange={formikField.onChange}
                                                            id={field.name}
                                                        />
                                                    )}
                                                </Box>
                                                <FormErrorMessage>{form.errors[formikField.name]}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                ))}
                            </ModalBody>

                            <ModalFooter>
                                <Button colorScheme="blue" mr={3} type="submit" isLoading={isSubmitting}>
                                    Submit
                                </Button>
                                <Button variant="outline" className='dark:text-white hover:text-gray-700' onClick={onClose}>Cancel</Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </ModalContent>
        </Modal>
    );
};

export default GenericModalForm;