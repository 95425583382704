// Component for rendering editable cells
import { IoMdCheckmarkCircle, IoMdCloseCircle } from "react-icons/io";
import { MdEdit } from "react-icons/md";

const EditableCell = ({
    isEditing,
    value,
    displayValue,
    editValue,
    setEditValue,
    field,
    rowId,
    onSave,
    onEdit,
    onCancel,
    isSplit = false,
    className = "",
}) => (
    <div className={`flex items-center ${className}`}>
        {isEditing ? (
            <>
                {isSplit && (
                    <p className="text-sm font-bold text-navy-700 dark:text-white mr-2">
                        {displayValue} /
                    </p>
                )}
                <input
                    className="text-sm font-bold text-navy-700 dark:!border-white/10 dark:text-navy-700 pl-2 mr-2 max-w-10"
                    type="number"
                    value={editValue}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            onSave(rowId, field);
                        }
                    }}
                    onChange={(e) => setEditValue(e.target.value)}
                    autoFocus
                />
                <IoMdCheckmarkCircle
                    className="text-xl text-green-400 cursor-pointer mr-2"
                    onClick={() => onSave(rowId, field)}
                />
                <IoMdCloseCircle
                    className="text-xl text-red-400 cursor-pointer"
                    onClick={onCancel}
                />
            </>
        ) : (
            <>
                <p className="text-sm font-bold text-navy-700 dark:text-white mr-2">
                    {value}
                </p>
                <MdEdit
                    className="cursor-pointer"
                    onClick={() => onEdit(rowId, field, value)}
                />
            </>
        )}
    </div>
);

export default EditableCell