import { createColumnHelper } from "@tanstack/react-table";
import JsonCell from "components/tableCell/JsonCell";
import TableHeader from "components/tableCell/TableHeader";
import React from 'react';

const columnHelper = createColumnHelper();

const BlockCallerColumns = () => [
    columnHelper.accessor("module", {
        id: "module",
        header: () => <TableHeader title="Module" className="text-center" />,
        cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue()}</p>,
    }),
    columnHelper.accessor("action", {
        id: "action",
        header: () => <TableHeader title="Action" className="text-center" />,
        cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue()}</p>,
    }),
    columnHelper.accessor("old_data", {
        id: "old_data",
        header: () => <TableHeader title="Old Data" className="text-center" />,
        cell: (info) => <JsonCell data={info.getValue()} />,
    }),
    columnHelper.accessor("new_data", {
        id: "new_data",
        header: () => <TableHeader title="New Data" className="text-center" />,
        cell: (info) => <JsonCell data={info.getValue()} />,
    }),
    columnHelper.accessor("creation_date", {
        id: "creation_date",
        header: () => <TableHeader title="Date" className="text-center" />,
        cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue()}</p>,
    }),
    columnHelper.accessor("ip", {
        id: "ip",
        header: () => <TableHeader title="IP Address" className="text-center" />,
        cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue()}</p>,
    }),
];


export default BlockCallerColumns;
