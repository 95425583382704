import React, { useState } from 'react'
import GenericTable from '../GenericTable'
import apiRouteConstants from 'constants/apiRouteConstants'
import BillingHistoryColumns from './BillingHistoryColumns'
import { SingleDatepicker } from 'components/fields/Datepicker';
import { isAfter } from 'date-fns';

const BillingHistoryTable = () => {
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

    const handleFromDateChange = (date) => {
        if (toDate && isAfter(date, toDate)) {
            setFromDate(toDate);
            setToDate(date);
        } else {
            setFromDate(date);
        }
    };

    const handleToDateChange = (date) => {
        if (fromDate && isAfter(fromDate, date)) {
            setToDate(fromDate);
            setFromDate(date);
        } else {
            setToDate(date);
        }
        if (!date) {
            setFromDate(null);
        }
    };

    const FilterComponents = () => (
        <div className='flex items-center gap-4'>
            <SingleDatepicker
                label="From date"
                placeholder="Select From Date"
                maxDate={new Date()}
                date={fromDate}
                onDateChange={handleFromDateChange}
            />
            <SingleDatepicker
                label="To date"
                placeholder="Select To Date"
                maxDate={new Date()}
                date={toDate}
                onDateChange={handleToDateChange}
            />
        </div>
    );

    return (
        <div>
            <GenericTable
                columns={BillingHistoryColumns()}
                fetchUrl={apiRouteConstants.billing}
                title="Billing History"
                filterComponents={FilterComponents}
                filterValues={{ fromDate, toDate }} />
        </div>
    )
}

export default BillingHistoryTable