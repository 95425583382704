import React, { useState } from 'react'
import { Button } from '@chakra-ui/react';
import GenericTable from '../GenericTable'
import apiRouteConstants from 'constants/apiRouteConstants'
import BlockCallerColumns from './BlockCallerColumns'
import GenericModalForm from 'components/form/GenericForm';
import * as Yup from "yup"
import { post } from 'utils/apiClient';
import InputField from 'components/fields/InputField';

const formFields = [
    {
        name: 'number',
        label: 'Number',
        type: 'number',
        placeholder: 'Enter number',
        isRequired: true,
        validation: Yup.string()
            .required('Number is required'),
    },
    {
        name: 'description',
        label: 'Description',
        type: 'text',
        placeholder: 'Enter description',
        isRequired: true,
        validation: Yup.string()
            .required('Description is required'),
    },
    {
        name: 'status',
        label: 'Status',
        type: 'checkbox',
    },
]

const BlockCallerTable = () => {
    const [fetchKey, setFetchKey] = useState(0);
    const [filters, setFilters] = useState({ status: "" })
    const [isModalOpen, setModalOpen] = useState(false)

    const FilterComponents = () => (
        <div>
            <InputField
                value={filters.search}
                placeholder="Search Block caller"
                label="Search"
                onChange={(e) => {
                    setFilters({
                        ...filters,
                        search: e.target.value,
                    });
                }}
            />
        </div>
    );

    const createButton = () => (
        <Button
            onClick={() => setModalOpen(true)}
            className='dark:!bg-white/5 dark:border rounded-xl border bg-white/0 p-3 text-sm border-gray-200 dark:!border-white/10 dark:text-white'
        >
            Create
        </Button>
    )

    const handleSubmit = async (values) => {
        // Implement form submission logic here
        try {
            console.log(values);
            values["status"] = values.status ? 1 : 0
            await post(apiRouteConstants.callBlock.create, values)
            setModalOpen(false);
            setFetchKey(prevKey => prevKey + 1);
        } catch (err) {
            console.log(err);
        }
    };

    const handlePartialUpdate = async (callBlockId, data) => {
        try {
            await post(`${apiRouteConstants.callBlock.statusChange}/${callBlockId}`, data)
            setFetchKey(prevKey => prevKey + 1);
        } catch (err) {
            console.log(err);
        }
    }

    const handleDelete = async (callBlockId) => {
        try {
            await post(`${apiRouteConstants.callBlock.delete}/${callBlockId}`)
            setFetchKey(prevKey => prevKey + 1);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div>
            <div>
                <GenericTable
                    key={fetchKey}
                    createButton={createButton}
                    columns={BlockCallerColumns({ handleDelete, handlePartialUpdate })}
                    fetchUrl={apiRouteConstants.callBlock.list}
                    title="Block Callerd ID"
                    filterComponents={FilterComponents}
                    filterValues={filters} />
            </div>
            <GenericModalForm
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                title="Campaign Form"
                formFields={formFields}
                // initialValues={initialValues} // Pass initialValues only if editing
                onSubmit={handleSubmit}
            />
        </div>
    )
}

export default BlockCallerTable