import { useState } from "react";
import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";
import { post } from "utils/apiClient";
import apiRouteConstants from "constants/apiRouteConstants";
import { useNavigate } from "react-router-dom";

export default function SignIn() {
    const [userDetails, setUserDetails] = useState({ emailOrNumber: "", password: "" })
    const navigate = useNavigate();

    const handleSignIn = async () => {
        try {
            console.log("called");
            const res = await post(apiRouteConstants.auth, userDetails)
            if (res.data.token) {
                localStorage.setItem("token", res.data.token);
                localStorage.setItem("user", JSON.stringify(res.data.user));
                navigate("/admin");
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10">
            {/* Sign in section */}
            <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                    Sign In
                </h4>
                <p className="mb-9 ml-1 text-base text-gray-600">
                    Enter your email and password to sign in!
                </p>
                {/* Email */}
                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Email or Phone*"
                    placeholder="mail@simmmple.com"
                    id="emailOrNumber"
                    type="text"
                    onChange={(e) => setUserDetails({ ...userDetails, emailOrNumber: e.target.value })}
                />

                {/* Password */}
                <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Password*"
                    placeholder="Min. 8 characters"
                    id="password"
                    type="password"
                    value={userDetails.password}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSignIn()
                        }
                    }}
                    onChange={(e) => setUserDetails({ ...userDetails, password: e.target.value })}
                />
                {/* Checkbox */}
                <div className="mb-4 flex items-center justify-between px-2">
                    <div className="flex items-center">
                        <Checkbox />
                        <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                            Keep me logged In
                        </p>
                    </div>
                </div>
                <button className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200" onClick={() => handleSignIn()}>
                    Sign In
                </button>
            </div>
        </div>
    );
}
