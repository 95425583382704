import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem("token");

    if (!token) {
        // If the user is not authenticated, redirect to the sign-in page
        return <Navigate to="/auth/sign-in" replace />;
    }

    // If the user is authenticated, render the requested route
    return children;
};

export default ProtectedRoute;
