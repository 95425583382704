// src/components/NotFound.jsx
import React from 'react';

const NotFound = () => {
    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900 px-4 sm:px-6 lg:px-8">
            <div className="text-center">
                <h1 className="text-5xl sm:text-6xl font-bold text-gray-800 dark:text-gray-100">404</h1>
                <p className="mt-4 text-base sm:text-lg text-gray-600 dark:text-gray-300">
                    Page Not Found
                </p>
                <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                    The page you are looking for might have been moved or deleted.
                </p>
                <a
                    href="/"
                    className="mt-6 inline-block px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 dark:bg-blue-400 dark:hover:bg-blue-500"
                >
                    Go to Home
                </a>
            </div>
        </div>
    );
};

export default NotFound;
