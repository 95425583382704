import React, { useState } from 'react'
import apiRouteConstants from 'constants/apiRouteConstants'
import CallReportColumns from './CallReportColumns'
import InputField from 'components/fields/InputField';
import { SingleDatepicker } from 'components/fields/Datepicker';
import SelectField from 'components/fields/SelectField';
import ExpandableTable from '../GenericExpandableTable';
import { Badge, Button } from '@chakra-ui/react';
import GenericModal from 'components/modal/GenericModal';
import { MdDownload } from 'react-icons/md';
import { getBlob } from 'utils/apiClient';
import { cleanObject } from 'utils/helperFunctions';

const CallReportTable = () => {
    const [filters, setFilters] = useState({ status: "" })
    const [isModalOpen, setModalOpen] = useState(false)
    const [recordingData, setRecordingData] = useState()

    const FilterComponents = () => (
        <div className='flex gap-4 flex-col md:flex-row md:max-w-[90%] w-full flex-wrap'>
            <SingleDatepicker
                value={filters.fromDate}
                label="From Date"
                placeholder="Select From Date"
                maxDate={new Date()}
                date={filters.fromDate}
                onDateChange={(date) => setFilters({
                    ...filters,
                    fromDate: date,
                })}
            />
            <SingleDatepicker
                value={filters.toDate}
                label="To Date"
                placeholder="Select To Date"
                maxDate={new Date()}
                date={filters.toDate}
                onDateChange={(date) => setFilters({
                    ...filters,
                    toDate: date,
                })}
            />
            <InputField
                value={filters.callerID}
                label="Caller ID"
                placeholder="Type Caller ID"
                onChange={(e) => {
                    setFilters({
                        ...filters,
                        callerID: e.target.value,
                    });
                }}
            />
            <InputField
                value={filters.did}
                label="DID"
                placeholder="Type DID"
                onChange={(e) => {
                    setFilters({
                        ...filters,
                        did: e.target.value,
                    });
                }}
            />
            <InputField
                value={filters.target}
                label="Target"
                placeholder="Type Target"
                onChange={(e) => {
                    setFilters({
                        ...filters,
                        target: e.target.value,
                    });
                }}
            />
            <InputField
                value={filters.campaign}
                label="Campaign"
                placeholder="Type Campaign"
                onChange={(e) => {
                    setFilters({
                        ...filters,
                        campaign: e.target.value,
                    });
                }}
            />
            <SelectField
                value={filters.call_status}
                label="Call Status"
                flex="1"
                extra="min-w-36"
                options={[{ label: "Missed", value: 1 }, { label: "Answered", value: 0 }]}
                placeholder="All"
                onChange={(e) => {
                    if (e.target.value) {
                        setFilters({
                            ...filters,
                            call_status: Number(e.target.value),
                        });
                    } else {
                        setFilters({ call_status: "" });
                    }
                }}
            />
        </div>
    );

    const renderExpandedRow = (row) => {
        return (
            <div className='grid grid-cols-3 gap-2 md:grid-cols-6'>
                {row.original.call_answer && <div className="p-2 flex h-full w-auto items-center justify-around rounded-lg bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white px-3">
                    <b className='text-xs'>Call Answered :</b>  <span className='pl-1 text-xs'>{row.original.call_answer}</span>
                </div>}
                {row.original.caller_carrier && <div className="p-2 flex h-full w-auto items-center justify-around rounded-lg bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white px-3">
                    <b className='text-xs'>Caller Carrier : </b> <span className='pl-1 text-xs'>{row.original.caller_carrier}</span>
                </div>}
                {row.original.caller_country && <div className="p-2 flex h-full w-auto items-center justify-around rounded-lg bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white px-3">
                    <b className='text-xs'>Caller Country : </b> <span className='pl-1 text-xs'>{row.original.caller_country}</span>
                </div>}
                {row.original.caller_linetype && <div className="p-2 flex h-full w-auto items-center justify-around rounded-lg bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white px-3">
                    <b className='text-xs'>Caller Linetype : </b> <span className='pl-1 text-xs'>{row.original.caller_linetype}</span>
                </div>}
                {row.original.caller_name && <div className="p-2 flex h-full w-auto items-center justify-around rounded-lg bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white px-3">
                    <b className='text-xs'>Caller Name : </b> <span className='pl-1 text-xs'>{row.original.caller_name}</span>
                </div>}
                <div className="p-2 flex h-full w-auto items-center justify-around rounded-lg bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white px-3">
                    <b className='text-xs'>Abuse Caller :</b> <Badge fontSize={"x-small"} className="ml-2 p-1 !rounded-lg" colorScheme={row.original.abuse_caller === 'false' ? 'green' : 'red'}>{row.original.abuse_caller === 'false' ? "No" : "Yes"}</Badge>
                </div>
                <div className="p-2 flex h-full w-auto items-center justify-around rounded-lg bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white px-3">
                    <b className='text-xs'>Abuse Caller :</b> <Badge fontSize={"x-small"} className="ml-2 p-1 !rounded-lg" colorScheme={row.original.caller_risky === 'false' ? 'green' : 'red'}>{row.original.caller_risky === 'false' ? "No" : "Yes"}</Badge>
                </div>
                <div className="p-2 flex h-full w-auto items-center justify-around rounded-lg bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white px-3">
                    <b className='text-xs'>Caller Spammer :</b> <Badge fontSize={"x-small"} className="ml-2 p-1 !rounded-lg" colorScheme={row.original.caller_spammer === 'false' ? 'green' : 'red'}>{row.original.caller_spammer === 'false' ? "No" : "Yes"}</Badge>
                </div>
                <div className="p-2 flex h-full w-auto items-center justify-around rounded-lg bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white px-3">
                    <b className='text-xs'>Caller Valid :</b> <Badge fontSize={"x-small"} className="ml-2 p-1 !rounded-lg" colorScheme={row.original.caller_valid === 'false' ? 'red' : 'green'}>{row.original.caller_valid === 'false' ? "No" : "Yes"}</Badge>
                </div>
                <div className="p-2 flex h-full w-auto items-center justify-around rounded-lg bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white px-3">
                    <b className='text-xs'>Caller Voip :</b> <Badge fontSize={"x-small"} className="ml-2 p-1 !rounded-lg" colorScheme={row.original.caller_voip === 'false' ? 'green' : 'red'}>{row.original.caller_voip === 'false' ? "No" : "Yes"}</Badge>
                </div>
                {row.original.caller_voip && <div className="p-2 flex h-full w-auto items-center justify-around rounded-lg bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white px-3">
                    <b className='text-xs'>Fraud Score :</b> <Badge fontSize={"x-small"} className="ml-2 p-1 !rounded-lg" colorScheme={Number(row.original.caller_voip) > 50 ? 'red' : 'green'}>{row.original.fraudscore}</Badge>
                </div>}
            </div>
        )
    }

    const handleShowRecording = (data) => {
        setRecordingData(data);
        setModalOpen(true);
    }

    const handleDownloadReport = async () => {
        try {
            const filtersToSend = cleanObject(filters)
            const { data } = await getBlob(apiRouteConstants.cdrExport, filtersToSend)

            const blob = new Blob([data], { type: 'text/csv' });

            // Create a link element
            const link = document.createElement('a');

            // Create a URL for the Blob and set it as the href attribute
            const url = window.URL.createObjectURL(blob);
            link.href = url;

            // Set the download attribute with a file name
            link.download = 'call_report.csv';

            // Append the link to the document body and trigger the download
            document.body.appendChild(link);
            link.click();

            // Clean up and remove the link after download
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);

        } catch (err) {
            console.log(err);
        }
    }

    const exportCallReportsComponent = () => (
        <Button
            onClick={() => handleDownloadReport()}
            className='dark:!bg-white/5 dark:border rounded-xl border bg-white/0 p-3 text-sm border-gray-200 dark:!border-white/10 dark:text-white'
        >
            <MdDownload className='mr-2' />
            Export
        </Button>
    )


    return (
        <div>
            <div>
                <ExpandableTable
                    createButton={exportCallReportsComponent}
                    columns={CallReportColumns({ handleShowRecording })}
                    fetchUrl={apiRouteConstants.cdr}
                    filterComponents={FilterComponents}
                    renderExpandedRow={renderExpandedRow}
                    filterValues={filters}
                />
            </div>
            <GenericModal isOpen={isModalOpen} title="Recording" onClose={() => { setModalOpen(false); setRecordingData(null) }}>
                <div className='p-4 flex justify-center'>
                    <audio controls>
                        <source src={recordingData} />
                        Your browser does not support the audio element.
                    </audio>
                </div>
            </GenericModal>
        </div>
    )
}

export default CallReportTable