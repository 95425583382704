import React, { useState, useEffect, useCallback, useRef } from "react";
import Card from "components/card";
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { get } from "utils/apiClient";
import { cleanObject } from "utils/helperFunctions";
import { Spinner } from "@chakra-ui/react";
import { MdExpandLess, MdExpandMore, MdFilterListAlt } from "react-icons/md";

export default function ExpandableTable({
    columns,
    fetchUrl,
    title,
    filterComponents,
    filterValues,
    extraParentClasses,
    createButton,
    renderExpandedRow
}) {
    const [showFiltersOnMobile, setShowFiltersOnMobile] = useState(false);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [sorting, setSorting] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [expandedRowIndex, setExpandedRowIndex] = useState(null);

    const abortControllerRef = useRef(null);
    const timeoutRef = useRef(null);

    const fetchData = useCallback(async () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();

        setIsLoading(true);
        try {
            const filterToSend = cleanObject(filterValues);

            const response = await get(fetchUrl, {
                page: currentPage,
                limit: entriesPerPage,
                ...filterToSend
            }, { signal: abortControllerRef.current.signal });

            setData(response.data.data);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            if (error.name !== 'AbortError') {
                console.error("Error fetching data:", error);
            }
        } finally {
            setIsLoading(false);
        }
    }, [fetchUrl, currentPage, entriesPerPage, filterValues]);

    const debouncedFetchData = useCallback(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            fetchData();
        }, 300);
    }, [fetchData]);

    useEffect(() => {
        debouncedFetchData();
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, [debouncedFetchData]);

    useEffect(() => {
        setCurrentPage(1);
    }, [filterValues]);

    const toggleFilters = () => setShowFiltersOnMobile(!showFiltersOnMobile);

    const table = useReactTable({
        data,
        columns,
        state: { sorting },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleEntriesChange = (newEntriesPerPage) => {
        setEntriesPerPage(newEntriesPerPage);
        setCurrentPage(1); // Reset to first page when changing entries per page
    };

    const toggleExpandRow = (rowIndex) => {
        setExpandedRowIndex((prevIndex) => (prevIndex === rowIndex ? null : rowIndex));
    };

    return (
        <Card extra={`w-full h-full px-6 pb-6 sm:overflow-x-auto ${extraParentClasses}`}>
            <div className="relative flex flex-col gap-4 items-start justify-between pt-8 md:pt-4">
                <div className="flex items-center justify-between w-full">
                    <div className="flex items-center">
                        <div className="md:hidden mr-2">
                            {filterComponents && (
                                <button
                                    className="py-2 px-3 text-sm items-center font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                    onClick={toggleFilters}
                                >
                                    <MdFilterListAlt />
                                </button>
                            )}
                        </div>
                        <div className="hidden md:block">
                            {filterComponents && filterComponents()}
                        </div>
                    </div>
                    <div className="flex gap-3">
                        {createButton && createButton()}
                    </div>
                </div>

                <div className={`w-full pt-2 ${showFiltersOnMobile ? 'block' : 'hidden'} md:hidden`}>
                    <div className="flex justify-start w-full">
                        {filterComponents && filterComponents()}
                    </div>
                </div>
            </div>

            <div className="mt-6 overflow-x-auto">
                {isLoading ?
                    <div className="w-full min-h-96 flex justify-center items-center">
                        <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='blue.500'
                            size='xl'
                        />
                    </div>
                    : data.length === 0 ? (
                        <div className="w-full min-h-96 flex justify-center items-center">
                            <p className="text-lg text-gray-500">No records to show</p>
                        </div>
                    ) :
                        <table className="w-full overflow-y-auto">
                            <thead className="bg-gray-100 dark:bg-navy-400">
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id} className="!border-px !border-gray-400">
                                        <th className="w-8"></th>
                                        {headerGroup.headers.map((header) => (
                                            <th
                                                key={header.id}
                                                colSpan={header.colSpan}
                                                className="py-3 text-left text-sm font-semibold text-gray-900 dark:text-white"
                                            >
                                                <div className="items-center justify-between text-xs text-gray-200">
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                    {{
                                                        asc: "",
                                                        desc: "",
                                                    }[header.column.getIsSorted()] ?? null}
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>

                            <tbody>
                                {table.getRowModel().rows.map((row, index) => (
                                    <React.Fragment key={row.id}>
                                        <tr>
                                            <td className="min-w-[40px] text-center cursor-pointer" onClick={() => toggleExpandRow(index)}>
                                                <button>
                                                    {expandedRowIndex === index ? (
                                                        <MdExpandLess className="text-lg" />
                                                    ) : (
                                                        <MdExpandMore className="text-lg" />
                                                    )}
                                                </button>
                                            </td>
                                            {row.getVisibleCells().map((cell) => (
                                                <td
                                                    key={cell.id}
                                                    className="min-w-content border-white/0 py-3"
                                                >
                                                    {flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext()
                                                    )}
                                                </td>
                                            ))}
                                        </tr>
                                        {expandedRowIndex === index && (
                                            <tr>
                                                <td colSpan={columns.length + 1} className="bg-transparent p-4">
                                                    {renderExpandedRow(row)}
                                                </td>
                                            </tr>
                                        )}
                                        {index < table.getRowModel().rows.length - 1 && (
                                            <tr>
                                                <td colSpan={columns.length + 1} className="border-b border-gray-200"></td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>}
            </div>

            <div className="flex items-center justify-between pt-4 border-t-[1px] border-gray-200">
                <div>
                    <label className="text-sm font-bold text-gray-600 dark:text-white">
                        Entries per page:
                        <select
                            value={entriesPerPage}
                            onChange={(e) => handleEntriesChange(Number(e.target.value))}
                            className="ml-2 p-1 border border-gray-300 rounded dark:bg-gray-800 dark:text-white"
                        >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                    </label>
                </div>
                <div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </div>
            </div>
        </Card>
    );
}

function Pagination({ currentPage, totalPages, onPageChange }) {
    const generatePageNumbers = () => {
        let pages = [];
        if (totalPages <= 10) {
            // If total pages are 10 or less, show all pages
            pages = [...Array(totalPages)].map((_, index) => index + 1);
        } else {
            // Always show the first page
            pages.push(1);

            // Determine the start and end of the range around the current page
            let startPage = Math.max(2, currentPage - 1);
            let endPage = Math.min(totalPages - 1, currentPage + 1);

            if (startPage > 2) {
                // Add a gap if there's a space between the first page and the start of the range
                pages.push("...");
            }

            // Add the range of pages around the current page
            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }

            if (endPage < totalPages - 1) {
                // Add a gap if there's a space between the end of the range and the last page
                pages.push("...");
            }

            // Always show the last page
            pages.push(totalPages);
        }

        return pages;
    };

    const pages = generatePageNumbers();

    return (
        <div className="flex items-center justify-center">
            <button
                className="text-sm font-bold text-gray-600 dark:text-white mx-2"
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
            >
                Previous
            </button>
            {pages.map((page, index) =>
                page === "..." ? (
                    <span key={index} className="text-sm font-bold mx-1 px-2">
                        ...
                    </span>
                ) : (
                    <button
                        key={index}
                        className={`text-sm font-bold mx-1 px-2 ${currentPage === page
                            ? "text-blue-600 dark:text-white border-b-2 border-blue-600"
                            : "text-gray-600 dark:text-white"
                            }`}
                        onClick={() => onPageChange(page)}
                    >
                        {page}
                    </button>
                )
            )}
            <button
                className="text-sm font-bold text-gray-600 dark:text-white mx-2"
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            >
                Next
            </button>
        </div>
    );
}