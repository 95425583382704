import { RepeatIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import SelectField from 'components/fields/SelectField';
import apiRouteConstants from 'constants/apiRouteConstants';
import { useEffect, useState } from 'react';
import { get } from 'utils/apiClient';
import GenericTable from '../GenericTable';
import LiveCallColumns from './LiveCallColumns';

const LiveCallTable = () => {
    const [fetchKey, setFetchKey] = useState(0);
    const [campaignList, setCampaignList] = useState([]);
    const [filters, setFilters] = useState({ status: "" });
    const [autoRefetch, setAutoRefetch] = useState(false); // State to control auto-refetching

    const fetchCampaignList = async () => {
        try {
            const res = await get(apiRouteConstants.campaign.selectList);
            if (res.data && res.data.length > 0) {
                setCampaignList(res.data.map((camp) => {
                    return { label: camp.camp_name, value: camp.id };
                }));
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchCampaignList();
    }, []);

    const FilterComponents = () => (
        <div className='flex flex-col w-full gap-4 md:w-auto md:flex-row'>
            <InputField
                value={filters.search}
                placeholder="Search Live calls"
                label="Search"
                onChange={(e) => {
                    setFilters({
                        ...filters,
                        search: e.target.value,
                    });
                }}
            />
            <SelectField
                value={filters.campid}
                placeholder="Select Campaign"
                label="Campaign"
                options={campaignList}
                onChange={(e) => {
                    setFilters({
                        ...filters,
                        campid: e.target.value,
                    });
                }}
            />
        </div>
    );

    const handleHangup = async (uuid) => {
        try {
            await get(`${apiRouteConstants.hangupCall}/${uuid}`);
            setFetchKey(prevKey => prevKey + 1);
        } catch (err) {
            console.log(err);
        }
    };

    const toggleRefetch = () => {
        setAutoRefetch(prev => !prev);

    };

    const refetchButton = () => (
        <Button
            onClick={toggleRefetch}
            className={`dark:!bg-white/5 dark:border rounded-xl border bg-white/0 p-3 text-sm border-gray-200 dark:!border-white/10 dark:text-white ${autoRefetch ? 'dark:!bg-indigo-600' : ''}`}
        >
            <RepeatIcon
                className={`mr-2 ${autoRefetch ? 'animate-spin' : ''}`}
                w={4}
                h={4}
                fill='currentColor'
            />
            {autoRefetch ? 'Stop Refetch' : 'Refetch'}
        </Button>
    );

    return (
        <div>
            <GenericTable
                key={fetchKey}
                enableSilentRefetch={autoRefetch}
                refetchInterval={5000}
                createButton={refetchButton}
                columns={LiveCallColumns({ handleHangup })}
                fetchUrl={apiRouteConstants.liveCall}
                filterComponents={FilterComponents}
                filterValues={filters} />
        </div>
    );
}

export default LiveCallTable;
