import { createColumnHelper } from "@tanstack/react-table";
import Switch from "components/switch";
import EditableCell from "components/tableCell/EditableCell";
import TableHeader from "components/tableCell/TableHeader";
import { useState } from "react";

const columnHelper = createColumnHelper();

// Component for rendering the status cell
const StatusCell = ({ isActive, onToggle }) => (
    <div className="flex items-center justify-center">
        <Switch
            width="w-[108px]"
            checked={isActive}
            onChange={onToggle}
        />
    </div>
);

// Component for rendering the actions cell
const ActionCell = ({ onDelete, rowId }) => (
    <div className="flex items-center justify-center">
        <button
            className="text-xs text-white bg-red-700 dark:bg-red-500 px-2 py-1 rounded disabled:bg-gray-600"
            onClick={() => onDelete(rowId)}
        >
            Delete
        </button>
    </div>
);

const TargetColumns = ({ handleDelete, handlePartialUpdate }) => {
    const [editingCell, setEditingCell] = useState({ id: null, field: "" });
    const [currentDisplayValue, setCurrentDisplayValue] = useState("");
    const [currentEditValue, setCurrentEditValue] = useState("");

    const handleEditClick = (rowId, field, value) => {
        if (['dailyCap', 'monthlyCap', 'concurrentCalls'].includes(field)) {
            setCurrentDisplayValue(value.split('/')[0]);
            setCurrentEditValue(value.split('/')[1]);
        } else {
            setCurrentEditValue(value);
        }
        setEditingCell({ id: rowId, field });
    };

    const handleCancelClick = () => {
        setEditingCell({ id: null, field: "" });
        setCurrentEditValue("");
    };

    const handleSaveClick = (rowId, field) => {
        handlePartialUpdate(rowId, { [field]: currentEditValue });
        handleCancelClick();
    };

    return [
        columnHelper.accessor("number", {
            id: "number",
            header: () => <TableHeader title="Number" className="text-center" />,
            cell: (info) => (
                <p className="text-xs font-bold text-navy-700 dark:text-white text-center">
                    {info.getValue() || ''}
                </p>
            ),
        }),
        columnHelper.accessor("name", {
            id: "name",
            header: () => <TableHeader title="Name" className="text-center" />,
            cell: (info) => (
                <p className="text-xs font-bold text-navy-700 dark:text-white text-center">
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor("ahtToday", {
            id: "ahtToday",
            header: () => <TableHeader title="AHT Today" className="text-center" />,
            cell: (info) => (
                <p className="text-xs font-bold text-navy-700 dark:text-white text-center">
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor("todayAnswered", {
            id: "todayAnswered",
            header: () => <TableHeader title="Today Answered" className="text-center" />,
            cell: (info) => <div className="flex items-center justify-center">{info.getValue()}</div>,
        }),
        columnHelper.accessor("todayMissed", {
            id: "todayMissed",
            header: () => <TableHeader title="Today Missed" className="text-center" />,
            cell: (info) => <div className="flex items-center justify-center">{info.getValue()}</div>,
        }),
        columnHelper.accessor("dailyCap", {
            id: "dailyCap",
            header: () => <TableHeader title="Daily Cap" className="text-center" />,
            cell: (info) => (
                <EditableCell
                    className="justify-center"
                    isEditing={editingCell.id === info.row.original.id && editingCell.field === "dailyCap"}
                    value={info.getValue()}
                    displayValue={currentDisplayValue}
                    editValue={currentEditValue}
                    setEditValue={setCurrentEditValue}
                    field="dailyCap"
                    rowId={info.row.original.id}
                    onSave={handleSaveClick}
                    onCancel={handleCancelClick}
                    onEdit={handleEditClick}
                    isSplit={true}
                />
            ),
        }),
        columnHelper.accessor("monthlyCap", {
            id: "monthlyCap",
            header: () => <TableHeader title="Monthly Cap" className="text-center" />,
            cell: (info) => (
                <EditableCell
                    className="justify-center"
                    isEditing={editingCell.id === info.row.original.id && editingCell.field === "monthlyCap"}
                    value={info.getValue()}
                    displayValue={currentDisplayValue}
                    editValue={currentEditValue}
                    setEditValue={setCurrentEditValue}
                    field="monthlyCap"
                    rowId={info.row.original.id}
                    onSave={handleSaveClick}
                    onEdit={handleEditClick}
                    onCancel={handleCancelClick}
                    isSplit={true}
                />
            ),
        }),
        columnHelper.accessor("concurrentCalls", {
            id: "concurrentCalls",
            header: () => <TableHeader title="Concurrent Calls" className="text-center" />,
            cell: (info) => (
                <EditableCell
                    className="justify-center"
                    isEditing={editingCell.id === info.row.original.id && editingCell.field === "concurrentCalls"}
                    value={info.getValue()}
                    displayValue={currentDisplayValue}
                    editValue={currentEditValue}
                    setEditValue={setCurrentEditValue}
                    field="concurrentCalls"
                    rowId={info.row.original.id}
                    onSave={handleSaveClick}
                    onEdit={handleEditClick}
                    onCancel={handleCancelClick}
                    isSplit={true}
                />
            ),
        }),
        columnHelper.accessor("priority", {
            id: "priority",
            header: () => <TableHeader title="Priority/Weight" className="text-center" />,
            cell: (info) => (
                <EditableCell
                    className="justify-center"
                    isEditing={editingCell.id === info.row.original.id && editingCell.field === "priority"}
                    value={info.getValue()}
                    editValue={currentEditValue}
                    setEditValue={setCurrentEditValue}
                    field="priority"
                    rowId={info.row.original.id}
                    onSave={handleSaveClick}
                    onEdit={handleEditClick}
                    onCancel={handleCancelClick}
                />
            ),
        }),
        columnHelper.accessor("status", {
            id: "status",
            header: () => <TableHeader title="Status" className="text-center" />,
            cell: (info) => (
                <StatusCell
                    isActive={Boolean(info.getValue())}
                    onToggle={() => handlePartialUpdate(info.row.original.id, { active: Number(info.getValue()) === 1 ? 0 : 1 })}
                />
            ),
        }),
        columnHelper.accessor("actions", {
            id: "actions",
            header: () => <TableHeader title="Actions" className="text-center" />,
            cell: (info) => (
                <ActionCell
                    onDelete={handleDelete}
                    rowId={info.row.original.id}
                />
            ),
        }),
    ];
};

export default TargetColumns;
