import axios from "axios";
import { Bounce, toast } from "react-toastify";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "https://api.example.com";

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => {
    if (response.data.message) {
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
    }
    return Promise.resolve(response);
  },
  (error) => {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
      return Promise.reject(error);
    }

    if (error.response?.status === 401) {
      window.location.href = "/auth/sign-in";
    }
    if (error.response?.status === 400 || error.response?.status === 409) {
      let message = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong!";
      toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
    }

    return Promise.reject(error);
  }
);

export const get = (url, params = {}, config = {}) =>
  apiClient.get(url, { ...config, params });

export const getBlob = (url, params = {}, config = {}) =>
  apiClient.get(url, { ...config, params, responseType: "blob" });

export const post = (url, data, config = {}) =>
  apiClient.post(url, data, config);

export const put = (url, data, config = {}) => apiClient.put(url, data, config);

export const patch = (url, data, config = {}) =>
  apiClient.patch(url, data, config);

export const del = (url, config = {}) => apiClient.delete(url, config);

export default apiClient;
