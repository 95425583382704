import { createColumnHelper } from "@tanstack/react-table";
import TableHeader from "components/tableCell/TableHeader";
import React from 'react';

const columnHelper = createColumnHelper();

const BillingHistoryColumns = () => [
    columnHelper.accessor("created_date", {
        id: "created_date",
        header: () => <TableHeader title="Created Date" className="text-center" />,
        cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue()}</p>,
    }),
    columnHelper.accessor("item_type", {
        id: "item_type",
        header: () => <TableHeader title="Item Type" className="text-center" />,
        cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue()}</p>,
    }),
    columnHelper.accessor("description", {
        id: "description",
        header: () => <TableHeader title="Description" className="text-center" />,
        cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue()}</p>,
    }),
    columnHelper.accessor("debit", {
        id: "debit",
        header: () => <TableHeader title="Debit" className="text-center" />,
        cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue()}</p>,
    }),
    columnHelper.accessor("credit", {
        id: "credit",
        header: () => <TableHeader title="Credit" className="text-center" />,
        cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue()}</p>,
    }),
    columnHelper.accessor("before_balance", {
        id: "before_balance",
        header: () => <TableHeader title="Before Balance" className="text-center" />,
        cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue()}</p>,
    }),
    columnHelper.accessor("after_balance", {
        id: "after_balance",
        header: () => <TableHeader title="After Balance" className="text-center" />,
        cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue()}</p>,
    }),
];

export default BillingHistoryColumns;
