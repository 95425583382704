import { createColumnHelper } from "@tanstack/react-table";
import Switch from "components/switch";
import TableHeader from "components/tableCell/TableHeader";
import React from 'react';

const columnHelper = createColumnHelper();

const BlockCallerColumns = ({ handleDelete, handlePartialUpdate }) => [
    columnHelper.accessor("number", {
        id: "number",
        header: () => <TableHeader title="Number" className="text-center" />,
        cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue() || ''}</p>,
    }),
    columnHelper.accessor("description", {
        id: "description",
        header: () => <TableHeader title="Description" className="text-center" />,
        cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue() || ''}</p>,
    }),
    // other columns...
    columnHelper.accessor("status", {
        id: "status",
        header: () => <TableHeader title="Status" className="text-center" />,
        cell: (info) => (
            <div className="flex items-center justify-center">
                <Switch
                    width="w-[108px]"
                    checked={Boolean(Number(info.getValue()))}
                    onChange={() => handlePartialUpdate(info.row.original.id, { status: Number(info.getValue()) === 1 ? 0 : 1 })}
                />
            </div>
        ),
    }),
    columnHelper.accessor("actions", {
        id: "actions",
        header: () => <TableHeader title="Actions" className="text-center" />,
        cell: (info) => (
            <div className="flex items-center justify-center">
                <button
                    className="text-xs text-white bg-red-700 dark:bg-red-500 px-2 py-1 rounded disabled:bg-gray-600"
                    onClick={() => handleDelete(info.row.original.id)}
                >
                    Delete
                </button>
            </div>
        ),
    }),
];

export default BlockCallerColumns;
