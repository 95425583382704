import { Badge } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import TableHeader from "components/tableCell/TableHeader";
import React from 'react';
import { MdFileDownload, MdPlayCircle } from "react-icons/md";
import { Link } from "react-router-dom";

const columnHelper = createColumnHelper();

const CallReportColumns = ({ handleShowRecording }) => {
    // const downloadRecording = async () => {
    //     try {
    //         const res = await get()
    //     }
    // }
    return [
        columnHelper.accessor("call_start", {
            id: "call_start",
            header: () => <TableHeader title="Call Start" className="text-center" />,
            cell: ({ getValue }) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{getValue()}</p>,
        }),
        columnHelper.accessor("call_end", {
            id: "call_end",
            header: () => <TableHeader title="Call End" className="text-center" />,
            cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue()}</p>,
        }),

        columnHelper.accessor("callerid", {
            id: "callerid",
            header: () => <TableHeader title="Call ID" className="text-center" />,
            cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue()}</p>,
        }),
        columnHelper.accessor("did", {
            id: "did",
            header: () => <TableHeader title="DID" className="text-center" />,
            cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue()}</p>,
        }),
        columnHelper.accessor("forwardednumber", {
            id: "forwardednumber",
            header: () => <TableHeader title="Forwarded Number" className="text-center" />,
            cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white text-center">{info.getValue()}</p>,
        }),
        columnHelper.accessor("campname", {
            id: "campname",
            header: () => <TableHeader title="Campaign" className="text-center" />,
            cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue()}</p>,
        }),
        columnHelper.accessor("tta", {
            id: "tta",
            header: () => <TableHeader title="TTA" className="text-center" />,
            cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white text-center">{info.getValue()}</p>,
        }),
        columnHelper.accessor("billseconds", {
            id: "billseconds",
            header: () => <TableHeader title="Duration" className="text-center" />,
            cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white text-center">{info.getValue()}</p>,
        }),
        columnHelper.accessor("missed", {
            id: "missed",
            header: () => <TableHeader title="Call Status" className="text-center" />,
            cell: (info) => {
                return (
                    <div className="flex justify-center">
                        <Badge fontSize={"x-small"} className="p-2 !rounded-lg" colorScheme={info.getValue() === '1' ? 'red' : 'green'}>{info.getValue() === '1' ? "Missed" : "Answered"}</Badge>
                    </div>
                )
            },
        }),
        columnHelper.accessor("reason", {
            id: "reason",
            header: () => <TableHeader title="Reason" className="text-center" />,
            cell: (info) => <p className="text-xs font-bold text-navy-700 dark:text-white text-center">{info.getValue()}</p>,
        }),
        // columnHelper.accessor("duplicate", {
        //     id: "duplicate",
        //     header: () => <p className="text-xs font-bold text-gray-600 dark:text-white">Duplicate</p>,
        //     cell: (info) => {
        //         if (info.getValue() === 1) {
        //             return <Badge className="p-2 !rounded-lg" colorScheme='red'>Yes</Badge>
        //         } else {
        //             return <Badge colorScheme='green' className="p-2 !rounded-lg">No</Badge>
        //         }
        //     },
        // }),
        columnHelper.accessor("actions", {
            id: "actions",
            header: () => <TableHeader title="Recording" className="text-center" />,
            cell: (info) => {
                if (info.row.original.recordingfile) {
                    return (
                        <div className="flex gap-3 items-center justify-center">
                            <MdPlayCircle className="text-lg cursor-pointer" onClick={() => handleShowRecording(`${window.location.href.split('/admin/call-report')[0]}/audio/${info.row.original.recordingfile}`)} />
                            <Link to={`/audio/${info.row.original.recordingfile}`} download={true} target="_blank" referrerPolicy="no-referrer">
                                <MdFileDownload />
                            </Link>
                        </div>
                    )
                } else {
                    return <span className="text-xs text-center">No recording!</span>
                }
            },
        }),
    ]
};

export default CallReportColumns;
