import React, { useState } from "react";

const JsonCell = ({ data }) => {
    const [expanded, setExpanded] = useState(false);
    let parsedData = {};

    try {
        parsedData = JSON.parse(data);
    } catch (e) {
        return <p className="text-xs text-red-500 text-center">N/A</p>;
    }

    return (
        <div className="flex justify-center flex-col gap-2">
            <button
                onClick={() => setExpanded(!expanded)}
                className="text-xs font-bold text-blue-600 dark:text-blue-400"
            >
                {expanded ? "Hide Details" : "Show Details"}
            </button>
            {expanded && (
                <div className="mt-2 text-xs">
                    {Object.entries(parsedData).map(([key, value]) => (
                        <div key={key} className="mb-1">
                            <span className="text-blue-600 dark:text-blue-400 font-bold">{key}</span>:{" "}
                            <span className="text-navy-700 dark:text-white">{JSON.stringify(value)}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default JsonCell;
