import React, { useEffect, useState } from 'react'
import GenericTable from '../GenericTable'
import apiRouteConstants from 'constants/apiRouteConstants'
import DidColumns from './DidColumns'
import GenericModalForm from 'components/form/GenericForm';
import SelectField from 'components/fields/SelectField';
import * as Yup from "yup"
import { post } from 'utils/apiClient';
import { get } from 'utils/apiClient';
import InputField from 'components/fields/InputField';


const DidTable = () => {
    const [fetchKey, setFetchKey] = useState(0);
    const [filters, setFilters] = useState({ assigned: "" })
    const [isModalOpen, setModalOpen] = useState(false)
    const [campaignList, setCampaignList] = useState([])
    const [initialValues, setInitialValues] = useState({})

    const fetchCampaignList = async () => {
        try {
            const res = await get(apiRouteConstants.campaign.selectList)
            if (res.data && res.data.length > 0) {
                setCampaignList(res.data.map((camp) => {
                    return { label: camp.camp_name, value: camp.id };
                }))
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        fetchCampaignList();
    }, [])

    const formFields = [
        {
            name: 'campaignId',
            label: 'Campaign',
            type: 'select',
            placeholder: 'Select campaign',
            options: campaignList,
            isRequired: true,
            validation: Yup.string()
                .required('Campaign is required'),
        },
    ]

    const FilterComponents = () => (
        <div className='w-full md:w-auto flex flex-col gap-4 md:flex-row'>
            <SelectField
                value={filters.assigned}
                label="Assigned"
                flex="1"
                options={[{ label: "Assigned", value: 1 }, { label: "Unassigned", value: 0 }]}
                placeholder="All"
                onChange={(e) => {
                    if (e.target.value) {
                        setFilters({
                            ...filters,
                            assigned: Number(e.target.value),
                        });
                    } else {
                        setFilters({ assigned: "" });
                    }
                }}
            />
            <InputField
                value={filters.search}
                placeholder="Search DIDs"
                label="Search"
                onChange={(e) => {
                    setFilters({
                        ...filters,
                        search: e.target.value,
                    });
                }}
            />
        </div>
    );

    const handleSubmit = async (values) => {
        // Implement form submission logic here
        console.log(values);
        try {
            await post(apiRouteConstants.did.assign, values)
            setModalOpen(false);
            setFetchKey(prevKey => prevKey + 1);
        } catch (err) {
            console.log(err);
        }
    };

    const handlePartialUpdate = async (didId, data) => {
        try {
            await post(`${apiRouteConstants.did.edit}/${didId}`, data)
            setFetchKey(prevKey => prevKey + 1);
        } catch (err) {
            console.log(err);
        }
    }

    const handleAssign = async (data) => {
        if (data["campaignId"]) {
            for (let i = 0; i < campaignList.length; i++) {
                if (campaignList[i]["label"] === data["campaignId"]) {
                    data["campaignId"] = campaignList[i]["value"]
                    break;
                }
            }
        } else {
            data["campaignId"] = ""
        }
        setInitialValues(data)
        setModalOpen(true)
    }

    const handleDelete = async (data) => {
        try {
            await post(apiRouteConstants.did.unassign, data)
            setFetchKey(prevKey => prevKey + 1);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div>
            <div>
                <GenericTable
                    key={fetchKey}
                    columns={DidColumns({ handleDelete, handleAssign, handlePartialUpdate })}
                    fetchUrl={apiRouteConstants.did.list}
                    title="DIDs"
                    filterComponents={FilterComponents}
                    filterValues={filters} />
            </div>
            <GenericModalForm
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                title="Assign Campaign Form"
                formFields={formFields}
                initialValues={initialValues} // Pass initialValues only if editing
                onSubmit={handleSubmit}
            />
        </div>
    )
}

export default DidTable