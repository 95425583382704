import React, { Fragment, useRef, useState } from "react";
import lodash_isEmpty from "lodash/isEmpty";
import lodash_map from "lodash/map";
import lodash_isNil from "lodash/isNil";
import {
    Box,
    Button,
    Divider,
    Heading,
    HStack,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    SimpleGrid,
    Text,
    useOutsideClick,
    VStack
} from "@chakra-ui/react";
import {
    useDayzed,
} from "dayzed";
import { format, isAfter } from "date-fns";
import { MdCalendarMonth, MdClose } from "react-icons/md";

const MONTH_NAMES_DEFAULT = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
];
const DAY_NAMES_DEFAULT = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const DATE_FORMAT_DEFAULT = "yyyy-MM-dd";

const SingleDatepickerBackButtons = (
    props
) => {
    const { calendars, getBackProps } = props;
    return (
        <Fragment>
            <Button
                {...getBackProps({
                    calendars,
                    offset: 12
                })}
                variant="ghost"
                size="sm"
                className="dark:text-white"
            >
                {"<<"}
            </Button>
            <Button {...getBackProps({ calendars })} variant="ghost" size="sm" className="dark:text-white">
                {"<"}
            </Button>
        </Fragment>
    );
};

const SingleDatepickerForwardButtons = (
    props
) => {
    const { calendars, getForwardProps } = props;
    return (
        <Fragment>
            <Button className="dark:text-white" {...getForwardProps({ calendars })} variant="ghost" size="sm">
                {">"}
            </Button>
            <Button
                {...getForwardProps({
                    calendars,
                    offset: 12
                })}
                variant="ghost"
                className="dark:text-white"
                size="sm"
            >
                {">>"}
            </Button>
        </Fragment>
    );
};

const SingleDatepickerCalendar = (
    props
) => {
    const {
        calendars,
        getDateProps,
        getBackProps,
        getForwardProps,
        configs,
        minDate,
        maxDate
    } = props;

    if (lodash_isEmpty(calendars)) {
        return null;
    }

    return (
        <HStack className="datepicker-calendar">
            {lodash_map(calendars, (calendar) => {
                return (
                    <VStack key={`${calendar.month}${calendar.year}`}>
                        <HStack>
                            <SingleDatepickerBackButtons
                                calendars={calendars}
                                getBackProps={getBackProps}
                            />
                            <Heading size="sm" textAlign="center">
                                {configs.monthNames[calendar.month]} {calendar.year}
                            </Heading>
                            <SingleDatepickerForwardButtons
                                calendars={calendars}
                                getForwardProps={getForwardProps}
                            />
                        </HStack>
                        <Divider />
                        <SimpleGrid columns={7} spacing={2} textAlign="center">
                            {lodash_map(configs.dayNames, (day) => (
                                <Box key={`${calendar.month}${calendar.year}${day}`}>
                                    <Text fontSize="sm" fontWeight="semibold">
                                        {day}
                                    </Text>
                                </Box>
                            ))}
                            {lodash_map(calendar.weeks, (week, weekIndex) => {
                                return lodash_map(week, (dateObj, index) => {
                                    const {
                                        date,
                                        today,
                                        selected
                                    } = dateObj;
                                    const key = `${calendar.month}${calendar.year}${weekIndex}${index}`;

                                    const isDisabled =
                                        (minDate && date < minDate) ||
                                        (maxDate && isAfter(date, maxDate)); // Use isAfter for maxDate check

                                    return (
                                        <Button
                                            {...getDateProps({
                                                dateObj,
                                                disabled: isDisabled
                                            })}
                                            key={key}
                                            size="sm"
                                            variant="outline"
                                            borderColor={today ? "purple.400" : "transparent"}
                                            bg={selected ? "purple.200" : undefined}
                                            color="text-navy-600 dark:text-white"
                                            isDisabled={isDisabled}
                                        >
                                            {date.getDate()}
                                        </Button>
                                    );
                                });
                            })}
                        </SimpleGrid>
                    </VStack>
                );
            })}
        </HStack>
    );
};

export const SingleDatepicker = ({
    configs = {
        dateFormat: DATE_FORMAT_DEFAULT,
        monthNames: MONTH_NAMES_DEFAULT,
        dayNames: DAY_NAMES_DEFAULT
    },
    ...props
}) => {
    const { date, name, disabled, onDateChange, id, placeholder, label, minDate, maxDate, extra } = props;

    const ref = useRef(null);
    const initialFocusRef = useRef(null);
    const [popoverOpen, setPopoverOpen] = useState(false);

    const calendarIcon = <MdCalendarMonth className="text-gray-600 dark:text-gray-400" />;
    const closeIcon = <MdClose className="text-gray-600 dark:text-gray-400" />;

    const handleReset = () => {
        onDateChange(null);
        setPopoverOpen(false);
    };

    useOutsideClick({
        ref: ref,
        handler: () => setPopoverOpen(false)
    });

    const onDateSelected = (options) => {
        const { selectable, date } = options;
        if (!selectable) return;
        if (!lodash_isNil(date)) {
            onDateChange(format(date, configs.dateFormat));
            setPopoverOpen(false);
            return;
        }
    };

    const dayzedData = useDayzed({
        showOutsideDays: true,
        onDateSelected,
        selected: date
    });

    return (
        <Popover
            placement="bottom-start"
            isOpen={popoverOpen}
            onClose={() => setPopoverOpen(false)}
            initialFocusRef={initialFocusRef}
            isLazy
        >
            <PopoverTrigger>
                <div className={`${extra}`}>
                    {label && (
                        <label
                            htmlFor={id}
                            className="text-sm text-navy-700 dark:text-white ml-3 font-bold"
                        >
                            {label}
                        </label>
                    )}
                    <div className="relative mt-2">
                        <input
                            id={id}
                            ref={initialFocusRef}
                            disabled={disabled}
                            placeholder={placeholder}
                            name={name}
                            value={date ? format(date, configs.dateFormat) : ""}
                            onChange={(e) => e.target.value}
                            className={`flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none
                                ${disabled ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]" :
                                    "border-gray-200 dark:!border-white/10 dark:text-white"}
                            `}
                            onClick={() => setPopoverOpen(!popoverOpen)}
                        />
                        <div className="absolute right-3 top-3 flex space-x-1">
                            {date && <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleReset();
                                }}
                                className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full"
                            >
                                {closeIcon}
                            </button>}
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setPopoverOpen(!popoverOpen);
                                }}
                                className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full"
                            >
                                {calendarIcon}
                            </button>
                        </div>
                    </div>
                </div>
            </PopoverTrigger>
            <PopoverContent ref={ref} className="bg-white dark:bg-navy-700 border border-gray-200 dark:border-white/10">
                <PopoverBody className="p-2">
                    <SingleDatepickerCalendar {...dayzedData} configs={configs} minDate={minDate} maxDate={maxDate} />
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};
