import React from 'react'
import GenericTable from '../GenericTable'
import apiRouteConstants from 'constants/apiRouteConstants'
import UserActivityColumns from './UserActivityColumns'


const UserActivityTable = () => {

    return (
        <div>
            <GenericTable
                columns={UserActivityColumns()}
                fetchUrl={apiRouteConstants.userActivity}
            />
        </div>
    )
}

export default UserActivityTable