import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";

// Auth Imports
import SignIn from "views/auth/SignIn";
import Campaign from "views/admin/campaign";
import Target from "views/admin/target";
import BlockCaller from "views/admin/block-caller";
import Did from "views/admin/did";
import BillingHistory from "views/admin/billing-history";
import CallReport from "views/admin/call-report";
import LiveCall from "views/admin/live-call";
import UserActivity from "views/admin/user-activity";

// Icon Imports
import {
  MdBarChart,
  MdBlock,
  MdHome,
  MdLock,
  MdOutlinePhoneCallback,
  MdSummarize,
} from "react-icons/md";
import { IoDocuments } from "react-icons/io5";
import { RiBillLine } from "react-icons/ri";
import { FiActivity } from "react-icons/fi";

const routes = [
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    isVisible: false,
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "Dashboard",
    layout: "/admin",
    path: "default",
    isVisible: true,
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Campaign",
    layout: "/admin",
    path: "campaign",
    isVisible: true,
    icon: <MdBarChart className="h-6 w-6" />,
    component: <Campaign />,
  },
  {
    name: "Targets",
    layout: "/admin",
    isVisible: false,
    path: "campaign/:campaignId/targets",
    icon: <MdBarChart className="h-6 w-6" />,
    component: <Target />,
  },
  {
    name: "Block Caller ID",
    layout: "/admin",
    path: "block-caller",
    isVisible: true,
    icon: <MdBlock className="h-6 w-6" />,
    component: <BlockCaller />,
  },
  {
    name: "DID",
    layout: "/admin",
    path: "did",
    isVisible: true,
    icon: <IoDocuments className="h-6 w-6" />,
    component: <Did />,
  },
  {
    name: "Billing History",
    layout: "/admin",
    isVisible: true,
    path: "billing-history",
    icon: <RiBillLine className="h-6 w-6" />,
    component: <BillingHistory />,
  },
  {
    name: "Call Reports",
    layout: "/admin",
    isVisible: true,
    path: "call-report",
    icon: <MdSummarize className="h-6 w-6" />,
    component: <CallReport />,
  },
  {
    name: "Live Calls",
    layout: "/admin",
    isVisible: true,
    path: "live-calls",
    icon: <MdOutlinePhoneCallback className="h-6 w-6" />,
    component: <LiveCall />,
  },
  {
    name: "User Activity",
    layout: "/admin",
    isVisible: true,
    path: "user-activity",
    icon: <FiActivity className="h-6 w-6" />,
    component: <UserActivity />,
  },
  // {
  //   name: "NFT Marketplace",
  //   layout: "/admin",
  //   path: "nft-marketplace",
  //   icon: <MdOutlineShoppingCart className="h-6 w-6" />,
  //   component: <NFTMarketplace />,
  //   secondary: true,
  // },
  // {
  //   name: "Data Tables",
  //   layout: "/admin",
  //   icon: <MdBarChart className="h-6 w-6" />,
  //   path: "data-tables",
  //   component: <DataTables />,
  // },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "profile",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <Profile />,
  // },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "rtl",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <RTLDefault />,
  // },
];
export default routes;
