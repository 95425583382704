function cleanObject(obj) {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
      delete obj[key];
    }
  }
  return obj;
}

function isValidValue(value) {
  if (value === undefined || value === null || value === "") {
    return false;
  }
  return true;
}

export { cleanObject, isValidValue };
