
import { useEffect, useState } from "react";
import { get } from "utils/apiClient";
import apiRouteConstants from "constants/apiRouteConstants";
import Widget from "components/widget/Widget";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdCall, MdCallMissedOutgoing, MdCenterFocusStrong } from "react-icons/md";

const Dashboard = () => {
    const [dashboardData, setDashboardData] = useState({
        active_calls_count: "...",
        campaign_count: "...",
        did_count: "...",
        month_answered_calls: "...",
        month_missed_calls: "...",
        target_count: "...",
        today_answered_calls: "...",
        today_missed_calls: "...",
        active_campaign_count: "...",
        active_target_count: "...",
        active_did_count: "...",
    })

    const fetchDashboardData = async () => {
        try {
            const res = await get(apiRouteConstants.dashboard.cardStats)
            if (res.data) {
                setDashboardData(res.data);
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        fetchDashboardData();
    }, []);

    return (
        <div>
            {/* Card widget */}

            <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
                <Widget
                    icon={<MdBarChart className="h-7 w-7" />}
                    title={"Total Campaigns"}
                    subtitle={dashboardData.campaign_count}
                    actualValue={dashboardData.active_campaign_count ?? 0}
                />
                <Widget
                    icon={<IoDocuments className="h-6 w-6" />}
                    title={"Total DIDs"}
                    subtitle={dashboardData.did_count}
                    actualValue={dashboardData.active_did_count ?? 0}
                />
                <Widget
                    icon={<MdCenterFocusStrong className="h-7 w-7" />}
                    title={"Total Targets"}
                    subtitle={dashboardData.target_count}
                    actualValue={dashboardData.active_target_count ?? 0}
                />
                <Widget
                    icon={<MdCall className="h-6 w-6" />}
                    title={"Today Answered Calls"}
                    subtitle={dashboardData.today_answered_calls}
                />
                <Widget
                    icon={<MdCallMissedOutgoing className="h-7 w-7" />}
                    title={"Today Missed Calls"}
                    subtitle={dashboardData.today_missed_calls}
                />
                <Widget
                    icon={<MdCall className="h-6 w-6" />}
                    title={"Month Answered Calls"}
                    subtitle={dashboardData.month_answered_calls}
                />
                <Widget
                    icon={<MdCallMissedOutgoing className="h-6 w-6" />}
                    title={"Month Missed Calls"}
                    subtitle={dashboardData.month_missed_calls}
                />
            </div>


        </div>
    );
};

export default Dashboard;
