import { Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal'
import React from 'react'

const GenericModal = ({ children, isOpen, onClose, title }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent
                className='dark:!bg-navy-800 dark:!text-white !rounded-xl'
            >
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                {children}
            </ModalContent>
        </Modal>
    )
}

export default GenericModal