import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    primary: "#1DA1F2",
    secondary: "#14171A",
    accent: "#FFAD1F",
  },
  space: {
    72: "18rem",
    84: "21rem",
    96: "24rem",
  },
  fontSizes: {
    xs: ".75rem",
    sm: ".875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "4rem",
  },
  // Add more configurations as needed...
});

export default theme;
