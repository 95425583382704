import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import ProtectedRoute from "views/ProtectedRoute";
import NotFound from "views/NotFound";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import theme from "theme";
import { ChakraProvider } from "@chakra-ui/react";


const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Navigate to="/auth/sign-in" replace />} />
        <Route path="auth/*" element={<AuthLayout />} />
        <Route
          path="admin/*"
          element={
            <ProtectedRoute>
              <AdminLayout />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
        {/* <Route path="auth/*" element={<AuthLayout />} />
      <Route path="admin/*" element={<AdminLayout />} />
      <Route path="rtl/*" element={<RtlLayout />} />
      <Route path="/" element={<Navigate to="/admin" replace />} /> */}
      </Routes>
    </ChakraProvider>
  );
};

export default App;
