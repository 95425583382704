import { useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import Switch from "components/switch";
import { IoMdCheckmarkCircle, IoMdCloseCircle } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import TableHeader from "components/tableCell/TableHeader";

const columnHelper = createColumnHelper();

const DidColumns = ({ handleDelete, handleAssign, handlePartialUpdate }) => {
    const [editingCell, setEditingCell] = useState({ id: null, field: "" });
    const [currentEditValue, setCurrentEditValue] = useState("");

    const handleEditClick = (rowId, field, value) => {
        setEditingCell({ id: rowId, field });
        setCurrentEditValue(value);
    };

    const handleCancelClick = () => {
        setEditingCell({ id: null, field: "" });
        setCurrentEditValue("");
    };

    const handleSaveClick = (rowId, field) => {
        handlePartialUpdate(rowId, { [field]: currentEditValue });
        handleCancelClick();
    };

    return [
        columnHelper.accessor("number", {
            id: "number",
            header: () => (
                <TableHeader title="Number" className="text-center" />
            ),
            cell: (info) => (
                <p className="text-xs text-center font-bold text-navy-700 dark:text-white">
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor("campaign", {
            id: "campaign",
            header: () => (
                <TableHeader title="Campaign" className="text-center" />
            ),
            cell: (info) => (
                <p className="text-xs text-center font-bold text-navy-700 dark:text-white">
                    {info.getValue() || ""}
                </p>
            ),
        }),
        columnHelper.accessor("ivrAuth", {
            id: "ivrAuth",
            header: () => (
                <TableHeader title="IVR AUTH" className="text-center" />
            ),
            cell: (info) => (
                <div className="flex items-center justify-center">
                    <Switch
                        width="w-[108px]"
                        checked={Boolean(info.getValue())}
                        onChange={() =>
                            handlePartialUpdate(info.row.original.id, {
                                ivrauth: info.getValue() === 1 ? 0 : 1,
                            })
                        }
                    />
                </div>
            ),
        }),
        columnHelper.accessor("disableVoIPCalls", {
            id: "disableVoIPCalls",
            header: () => (
                <TableHeader title="Disable VoIP Calls" className="text-center" />
            ),
            cell: (info) => (
                <div className="flex items-center justify-center">
                    <Switch
                        width="w-[108px]"
                        checked={Boolean(info.getValue())}
                        onChange={() =>
                            handlePartialUpdate(info.row.original.id, {
                                disable_voip: info.getValue() === 1 ? 0 : 1,
                            })
                        }
                    />
                </div>
            ),
        }),
        columnHelper.accessor("spamScore", {
            id: "spamScore",
            header: () => (
                <TableHeader title="Spam Score" className="text-center" />
            ),
            cell: (info) => {
                const isEditing =
                    editingCell.id === info.row.original.id &&
                    editingCell.field === "spam_score";

                return (
                    <div className="flex items-center justify-center">
                        {isEditing ? (
                            <>
                                <input
                                    className="text-sm font-bold text-navy-700 dark:!border-white/10 dark:text-navy-700 pl-2 mr-2 max-w-10"
                                    value={currentEditValue}
                                    onChange={(e) =>
                                        setCurrentEditValue(e.target.value)
                                    }
                                    autoFocus
                                />
                                <IoMdCheckmarkCircle
                                    className="text-xl text-green-400 cursor-pointer mr-2"
                                    onClick={() =>
                                        handleSaveClick(
                                            info.row.original.id,
                                            "spam_score"
                                        )
                                    }
                                />
                                <IoMdCloseCircle
                                    className="text-xl text-red-400 cursor-pointer"
                                    onClick={handleCancelClick}
                                />
                            </>
                        ) : (
                            <>
                                <p className="text-sm font-bold text-navy-700 dark:text-white mr-2">
                                    {info.getValue()}
                                </p>
                                <MdEdit
                                    className="cursor-pointer"
                                    onClick={() =>
                                        handleEditClick(
                                            info.row.original.id,
                                            "spam_score",
                                            info.getValue()
                                        )
                                    }
                                />
                            </>
                        )}
                    </div>
                );
            },
        }),
        columnHelper.accessor("comments", {
            id: "comments",
            header: () => (
                <TableHeader title="Comments" className="text-center" />
            ),
            cell: (info) => {
                const isEditing =
                    editingCell.id === info.row.original.id &&
                    editingCell.field === "comments";

                return (
                    <div className="flex items-center justify-center">
                        {isEditing ? (
                            <>
                                <input
                                    className="text-sm font-bold text-navy-700 dark:!border-white/10 dark:text-navy-700 pl-2 mr-2 max-w-10 min-w-24"
                                    value={currentEditValue}
                                    onChange={(e) =>
                                        setCurrentEditValue(e.target.value)
                                    }
                                    autoFocus
                                />
                                <IoMdCheckmarkCircle
                                    className="text-xl text-green-400 cursor-pointer mr-2"
                                    onClick={() =>
                                        handleSaveClick(
                                            info.row.original.id,
                                            "comments"
                                        )
                                    }
                                />
                                <IoMdCloseCircle
                                    className="text-xl text-red-400 cursor-pointer"
                                    onClick={handleCancelClick}
                                />
                            </>
                        ) : (
                            <>
                                <p className="text-sm font-bold text-navy-700 dark:text-white mr-2">
                                    {info.getValue()}
                                </p>
                                <MdEdit
                                    className="cursor-pointer"
                                    onClick={() =>
                                        handleEditClick(
                                            info.row.original.id,
                                            "comments",
                                            info.getValue()
                                        )
                                    }
                                />
                            </>
                        )}
                    </div>
                );
            },
        }),
        columnHelper.accessor("actions", {
            id: "actions",
            header: () => (
                <TableHeader title="Actions" className="text-center" />
            ),
            cell: (info) => (
                <div className="flex items-center justify-center">
                    <button
                        className="text-xs text-white bg-navy-700 dark:bg-gray-800 px-2 py-1 rounded mr-2"
                        onClick={() =>
                            handleAssign(
                                {
                                    didId: info.row.original.id,
                                    campaignId: info.row.original.campaign
                                }
                            )
                        }
                    >
                        Assign
                    </button>
                    <button
                        className={`text-xs text-white bg-red-700 dark:bg-red-500 px-2 py-1 rounded disabled:bg-gray-600 ${!info.row.getValue("campaign") &&
                            "!bg-gray-600 dark:!bg-gray-600"
                            }`}
                        disabled={!info.row.getValue("campaign")}
                        onClick={() => handleDelete({ didId: info.row.original.id })}
                    >
                        Unassign
                    </button>
                </div>
            ),
        }),
    ];
};

export default DidColumns;
