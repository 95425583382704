import { createColumnHelper } from "@tanstack/react-table";
import TableHeader from "components/tableCell/TableHeader";
import React from 'react';

const columnHelper = createColumnHelper();

const LiveCallColumns = ({ handleHangup }) => [
    columnHelper.accessor("callerID", {
        id: "callerID",
        header: () => <TableHeader title="Caller ID" className="text-center" />,
        cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue() || ''}</p>,
    }),
    columnHelper.accessor("startTime", {
        id: "startTime",
        header: () => <TableHeader title="Start Time" className="text-center" />,
        cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue() || ''}</p>,
    }),
    columnHelper.accessor("answerTime", {
        id: "answerTime",
        header: () => <TableHeader title="Answer Time" className="text-center" />,
        cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue() || ''}</p>,
    }),
    columnHelper.accessor("callDuration", {
        id: "callDuration",
        header: () => <TableHeader title="Call Duration" className="text-center" />,
        cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue() || ''}</p>,
    }),
    // This column is used to hangup the call
    // columnHelper.accessor("callID", {
    //     id: "callID",
    //     header: () => <p className="text-xs text-center font-bold text-gray-600 dark:text-white">Call ID</p>,
    //     cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue() || ''}</p>,
    // }),
    columnHelper.accessor("did", {
        id: "did",
        header: () => <TableHeader title="DID" className="text-center" />,
        cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue() || ''}</p>,
    }),
    columnHelper.accessor("campaign", {
        id: "campaign",
        header: () => <TableHeader title="Campaign" className="text-center" />,
        cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue() || ''}</p>,
    }),
    columnHelper.accessor("target", {
        id: "target",
        header: () => <TableHeader title="Target" className="text-center" />,
        cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue() || ''}</p>,
    }),
    columnHelper.accessor("targetName", {
        id: "targetName",
        header: () => <TableHeader title="Buyer" className="text-center" />,
        cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue() || ''}</p>,
    }),
    columnHelper.accessor("status", {
        id: "status",
        header: () => <TableHeader title="Status" className="text-center" />,
        cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue() || ''}</p>,
    }),
    columnHelper.accessor("actions", {
        id: "actions",
        header: () => <TableHeader title="Actions" className="text-center" />,
        cell: (info) => (
            <div className="flex items-center justify-center">
                <button
                    className="text-xs text-white bg-red-700 dark:bg-red-500 px-2 py-1 rounded disabled:bg-gray-600"
                    onClick={() => handleHangup(info.row.original.callID)}
                >
                    Delete
                </button>
            </div>
        ),
    }),
];

export default LiveCallColumns;
