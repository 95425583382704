import React from "react";
import { MdClose } from "react-icons/md";
import {
    Select,
    IconButton,
    InputGroup,
    InputRightElement,
} from "@chakra-ui/react";
import { isValidValue } from "utils/helperFunctions";

function SelectField(props) {
    const {
        label,
        id,
        extra,
        placeholder,
        variant,
        state,
        value,
        disabled,
        options,
        onChange,
    } = props;

    const handleClearSelection = () => {
        onChange({ target: { value: "" } });
    };

    return (
        <div id={id} className={extra}>
            <label
                htmlFor={id}
                className={`text-sm text-navy-700 dark:text-white ml-3 font-bold`}
            >
                {label}
            </label>
            <InputGroup mt={2}>
                <Select
                    isDisabled={disabled}
                    id={id}
                    value={value}
                    placeholder={placeholder}
                    variant={variant}
                    icon={<></>}
                    onChange={onChange}
                    className={`!h-12 flex-grow p-2 rounded-lg border border-gray-300 bg-white dark:border-white/10 text-navy-700 dark:text-white`}
                    borderRadius="lg"
                >
                    {options.map((option) => (
                        <option
                            key={option.value}
                            value={option.value}
                            className="text-navy-700 dark:text-white dark:bg-navy-700"
                        >
                            {option.label}
                        </option>
                    ))}
                </Select>
                {!disabled && isValidValue(value) && (
                    <InputRightElement width="2.5rem">
                        <IconButton
                            icon={<MdClose />}
                            size="xs"
                            variant="ghost"
                            onClick={handleClearSelection}
                            className="bg-brandLinear mt-2"
                            aria-label="Clear selection"
                        />
                    </InputRightElement>
                )}
            </InputGroup>
        </div>
    );
}

export default SelectField;
