const apiRouteConstants = {
  auth: "/auth/login",
  billing: "/billing-history",
  userDetail: "/user-details",
  cdr: "/cdr",
  cdrExport: "/cdr/export",
  liveCall: "/live-calls",
  hangupCall: "/hangup-call",
  userActivity: "/user-activity",
  changePassword: "/change-password",
  dashboard: {
    cardStats: "/dashboard/card-stats",
    callStats: "/dashboard/call-stats",
  },
  did: {
    list: "/dids",
    edit: "/did/modify",
    assign: "/did/assign",
    unassign: "/did/unassign",
  },
  campaign: {
    list: "/campaigns",
    create: "/campaigns/createcamp",
    edit: "/campaigns/modify",
    delete: "/campaigns/delete",
    selectList: "/campaign/list",
  },
  callBlock: {
    list: "/call-block",
    create: "/callBlock/create",
    statusChange: "/callblock/status",
    delete: "/callBlock/delete",
  },
  target: {
    list: "/campaigns/targets",
    create: "/target/create",
    edit: "/target/modify",
    delete: "/target/delete",
  },
};

export default apiRouteConstants;
